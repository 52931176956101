import {API, Resource} from '@coveo/platform-client';
import {
    RecommendationFilterRuleModel,
    RecommendationPinRuleModel,
    RecommendationRankingRuleModel,
} from '../../interfaces';
import {CatalogSearch} from './CatalogSearch';
import {ListingPreview} from './ListingPreview';
import {ListingRulePreview} from './ListingRulePreview';
import {ListingRules} from './ListingRules';
import {ProductListingConfiguration} from './ProductListingConfiguration';
import {ProductListingReportingMetrics} from './ProductListingReportingMetrics';
import {RecommendationRulePreview} from './RecommendationRulePreview';
import {RecommendationRules} from './RecommendationRules';
import {RecommendationsMetrics} from './RecommendationsMetrics';
import {RecommendationSlotPreview} from './RecommendationSlotPreview';
import {Rules} from './Rules';
import {SearchConfigurations} from './SearchConfigurations';
import {SearchMetrics} from './SearchMetrics';
import {SearchPreview} from './SearchPreview';
import {SearchRulePreview} from './SearchRulePreview';
import {SearchRules} from './SearchRules';
import {SlotConfiguration} from './SlotConfiguration';
import {Synonyms} from './Synonyms';
import {TrackingIdLocales} from './TrackingIdLocales';
import {Catalog} from './Catalog';
import {Facets} from './Facets';

export class Commerce extends Resource {
    productListingReportingMetrics: ProductListingReportingMetrics;
    productListingConfigurationsV2: ProductListingConfiguration;
    listingPreview: ListingPreview;
    searchMetrics: SearchMetrics;
    searchRules: SearchRules;
    listingRules: ListingRules;
    searchConfiguration: SearchConfigurations;
    searchPreview: SearchPreview;
    trackingIdLocales: TrackingIdLocales;
    slotConfiguration: SlotConfiguration;
    recommendationMetrics: RecommendationsMetrics;
    recommendationSlotPreview: RecommendationSlotPreview;
    rules: Rules;
    searchRulePreview: SearchRulePreview;
    listingRulePreview: ListingRulePreview;
    synonyms: Synonyms;
    catalogSearch: CatalogSearch;
    recommendationRules: RecommendationRules;
    recommendationRulePreview: RecommendationRulePreview<
        RecommendationPinRuleModel | RecommendationFilterRuleModel | RecommendationRankingRuleModel
    >;
    catalog: Catalog;
    facets: Facets;

    constructor(
        protected api: API,
        protected serverlessApi: API,
    ) {
        super(api, serverlessApi);

        this.productListingReportingMetrics = new ProductListingReportingMetrics(api, serverlessApi);
        this.productListingConfigurationsV2 = new ProductListingConfiguration(api, serverlessApi);
        this.listingPreview = new ListingPreview(api, serverlessApi);
        this.searchMetrics = new SearchMetrics(api, serverlessApi);
        this.searchRules = new SearchRules(api, serverlessApi);
        this.listingRules = new ListingRules(api, serverlessApi);
        this.searchConfiguration = new SearchConfigurations(api, serverlessApi);
        this.searchPreview = new SearchPreview(api, serverlessApi);
        this.trackingIdLocales = new TrackingIdLocales(api, serverlessApi);
        this.slotConfiguration = new SlotConfiguration(api, serverlessApi);
        this.recommendationMetrics = new RecommendationsMetrics(api, serverlessApi);
        this.recommendationSlotPreview = new RecommendationSlotPreview(api, serverlessApi);
        this.rules = new Rules(api, serverlessApi);
        this.searchRulePreview = new SearchRulePreview(api, serverlessApi);
        this.listingRulePreview = new ListingRulePreview(api, serverlessApi);
        this.synonyms = new Synonyms(api, serverlessApi);
        this.catalogSearch = new CatalogSearch(api, serverlessApi);
        this.recommendationRules = new RecommendationRules(api, serverlessApi);
        this.recommendationRulePreview = new RecommendationRulePreview(api, serverlessApi);
        this.catalog = new Catalog(api, serverlessApi);
        this.facets = new Facets(api, serverlessApi);
    }
}
