import {AppShell, Box, Notifications} from '@components/mantine';
import {Navigation, useNavigation} from '@components/navigation';
import {NavigationBlockerProvider} from '@components/page';
import {IdleLogoutModal} from '@components/session';
import {UserFeedbackProvider} from '@components/user-feedback';
import {PlatformAppTheme} from '@configurations/platform';
import {AuthenticationEvents} from '@core/authentication';
import {LaunchDarklyFeatureFlags} from '@core/feature-flags';
import {LicenseProvider} from '@core/license';
import {Outlet} from '@core/routes';
import {getStore} from '@core/store';
import {UserProvider} from '@core/user';
import {SourcesListParamsProvider} from '@pages/source';
import {clsx} from 'clsx';
import {Children, FunctionComponent, ReactElement, ReactNode} from 'react';
import {Provider} from 'react-redux';

import {LinkHandler} from '../link-handler';
import {TopBar} from '../navigation';
import classes from './Layout.module.css';

interface LayoutProps {
    /**
     * Whether the layout should take the full screen
     *
     * @default false
     */
    fullScreen?: boolean;
    children?: ReactNode;
}

const Content: FunctionComponent<LayoutProps> = ({children, fullScreen}) => {
    const {collapsed} = useNavigation();
    const convertedChildren = Children.toArray(children) as ReactElement[];
    const footer = convertedChildren.find((child) => child.type === LayoutFooter);
    const menu = convertedChildren.find((child) => child.type === LayoutMenu);
    const childrenWithoutSections = convertedChildren.filter(
        (child) => child.type !== LayoutFooter && child.type !== LayoutMenu,
    );
    const onIdle = () => window['admin'].vent.trigger(AuthenticationEvents.FullLogout);

    return (
        <AppShell
            withBorder={!fullScreen}
            header={{height: fullScreen ? 0 : 72}}
            navbar={{width: fullScreen ? 0 : 245, breakpoint: 0, collapsed: {mobile: collapsed, desktop: collapsed}}}
        >
            <AppShell.Header id="admin-header" className={classes.appHeader} withBorder={false}>
                <TopBar />
            </AppShell.Header>
            {menu}
            <AppShell.Main className={classes.appShellMain}>
                <div className="CoveoAdminStatus" />
                <Box
                    id="application-container"
                    className={clsx(classes.scrollContent, 'CoveoAdminApplicationContainer application-container')}
                    data-testid="application-container"
                >
                    <div id="admin">
                        <div id="Alerts" />
                        <div id="organization">
                            <div id="ComponentHeader" className="component-header" />
                        </div>

                        <div id="main" className="flex flex-auto flex-column">
                            <Notifications position="top-center" zIndex={4000} />
                            <UserFeedbackProvider />
                            <Outlet />
                            {childrenWithoutSections}
                        </div>

                        <div id="Modal" className="modal" data-backdrop="static" tabIndex={-1} />
                        <div id="RModal" />
                        <div id="LoadingModal" className="modal modal-prompt" />
                    </div>
                </Box>
                {footer}
                <IdleLogoutModal onIdle={onIdle} />
                <LinkHandler />
            </AppShell.Main>
        </AppShell>
    );
};
const LayoutMenu: FunctionComponent<{children: ReactNode}> = ({children}) => <>{children}</>;
const LayoutFooter: FunctionComponent<{children: ReactNode}> = ({children}) => <>{children}</>;

interface LayoutType {
    (props: LayoutProps): ReactElement;
    Menu: typeof LayoutMenu;
    Footer: typeof LayoutFooter;
}

export const Layout: LayoutType = ({children, fullScreen = false}) => (
    <PlatformAppTheme>
        <Provider store={getStore()}>
            <NavigationBlockerProvider>
                <UserProvider>
                    <LicenseProvider>
                        <SourcesListParamsProvider>
                            <Navigation searchable={LaunchDarklyFeatureFlags.isActive('platform-navigation-search')}>
                                <Content fullScreen={fullScreen}>{children}</Content>
                            </Navigation>
                        </SourcesListParamsProvider>
                    </LicenseProvider>
                </UserProvider>
            </NavigationBlockerProvider>
        </Provider>
    </PlatformAppTheme>
);

Layout.Menu = LayoutMenu;
Layout.Footer = LayoutFooter;
