import {LicenseModel, Platform, useQuery} from '@core/api';
import {createContext, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {LicenseActions} from '../actions';

const LicenseContext = createContext<LicenseModel | undefined>(undefined);

export const useLicense = (): LicenseModel => {
    const context = useContext(LicenseContext);

    if (!context) {
        throw new Error('useLicense must be used within a LicenseProvider');
    }

    return context;
};

export const LicenseProvider = ({children}) => {
    const dispatch = useDispatch();
    const licenseQuery = useQuery({
        queryKey: ['init', 'license'],

        queryFn: async () => {
            const license = await Platform.license.full();
            dispatch(LicenseActions.update(license));
            return license;
        },
    });
    return licenseQuery.isPending || !licenseQuery.data ? null : (
        <LicenseContext.Provider value={licenseQuery.data}>{children}</LicenseContext.Provider>
    );
};
